<template>
  <b-card
    :header="
      this.$i18n.t('products.review-products') +
      ' ' +
      this.$i18n.t('products.products')
    "
    header-bg-variant="primary"
    header-text-variant="white"
  >
    <!-- <b-button :click="($i18n.locale = 'en')">asd</b-button> -->
    <ProductPopup v-if="selectedProduct" :data="selectedProduct" @onHidePopup="onClickChild" />
    <ModalProductMobileView :product="mobileViewProduct" />
    <FiltersAndSearch
      :searchKey.sync="searchKey"
      :priceSort.sync="priceSort"
      :activeOnly.sync="activeOnly"
      :sortOrder.sync="sortOrder"
    />
    <div>
      <ProductTypeSwitch :product-type-switch.sync="productTypeSwitch" />
    </div>
    <b-table
      sticky-header
      :fields="fields"
      :items="computedProductsList"
      bordered
      responsive
      hover
      class="products-table shadow-sm rounded"
      show-empty
      :empty-text="$t('global.no-items')"
      small
      @row-clicked="onRowClick"
    >
      <template #cell(nameGeo)="data">
        <div class="d-flex align-items-center">
          <div class="d-flex flex-column">
            <span>{{ data.item.nameGeo }}</span>
            <span>{{ data.item.nameEng }}</span>
          </div>
          <div
            v-if="data.item.serviceType === 4"
            class="ml-auto border border-info rounded-pill d-flex align-items-center"
          >
            <span class="text-info" style="font-size: 11px; padding: 0 0.5rem">
              moodie
            </span>
          </div>
        </div>
      </template>
      <template v-if="areActionsAllowed" #cell(actions)="data">
        <b-button
          variant="flat-primary"
          class="btn-icon"
          @click="editProduct(data.item)"
        >
          <feather-icon size="16" icon="EditIcon" />
        </b-button>
        <b-button
          variant="flat-primary"
          class="btn-icon"
          @click="dublicateProduct(data.item)"
        >
          <feather-icon size="16" icon="CopyIcon" />
        </b-button>
        <b-button
          class="btn-icon"
          variant="flat-danger"
          @click="removeProduct(data.item)"
        >
          <feather-icon size="16" icon="TrashIcon" />
        </b-button>
        <b-button
          class="btn-icon"
          variant="flat-success"
          @click="getMobileView(data.item)"
        >
          <feather-icon size="16" icon="EyeIcon" />
        </b-button>
      </template>
    </b-table>
    <Pagination
      :fetch-data="fetchProducts"
      :total-rows="totalRows"
      :take.sync="take"
      :skip.sync="skip"
    />
  </b-card>
</template>

<script>
// /* eslint-disable no-console */
import { BCard, BButton, BTable } from "bootstrap-vue";
import { mapActions, mapState } from "vuex";
import ModalProductMobileView from "@/views/products/components/ModalProductMobileView.vue";
import isAllowed from "@core/utils/isAllowed";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import ProductPopup from "./ProductPopup.vue";
import "vue-good-table/dist/vue-good-table.css";
import Pagination from "../components/Pagination/Pagination.vue";
import ProductTypeSwitch from "./components/ProductTypeSwitch.vue";
import FiltersAndSearch from "../components/FiltersAndSearch/FiltersAndSearch.vue";

export default {
  components: {
    BCard,
    BTable,
    BButton,
    ModalProductMobileView,
    ProductPopup,
    Pagination,
    ProductTypeSwitch,
    FiltersAndSearch,
  },
  data() {
    return {
      mobileViewProduct: null,
      pageLength: 10,
      dir: false,
      totalRows: 0,
      action: null,
      take: 20,
      skip: 0,
      selectedProduct: null,
      productTypeSwitch: "pending",
      productsList: [],
      productStatusList: {
        1: "Pending",
        2: "Rejected",
        3: "Approved",
        4: "Edited",
      },

      sortOrder: 2,
      id: "",
      searchKey: "",
      status: "",
      activeOnly: true,
      priceSort: false,
    };
  },
  computed: {
    ...mapState("products", ["products"]),
    areActionsAllowed() {
      // import isAllowed from "@core/utils/isAllowed";
      const roles = JSON.parse(localStorage.getItem("userRoles"));
      return isAllowed(["Admin", "Content Manager"], roles);
    },
    computedProductsList() {
      if (this.productTypeSwitch === "pending") {
        return this.productsList.filter(
          (e) => e.status === 1 || e.status === 4
        );
      }
      return this.productsList.filter((e) => e.status === 2);
    },
    fields() {
      return [
        {
          label: this.$i18n.t("products.actions"),
          key: "actions",
        },
        {
          label: this.$i18n.t("products.name"),
          key: "nameGeo",
          tdClass: "nameColumn",
        },
        {
          key: "price",
          label: this.$i18n.t("products.price"),
          sortable: false,
          formatter: (value, key, item) => {
            return `${item.price.toFixed(2)} ₾`
          },
        },
        {
          key: "count",
          label: this.$i18n.t("products.count"),
        },
        {
          key: "commissionFeePercent",
          label: this.$i18n.t("products.comission"),
        },
        {
          label: this.$i18n.t("products.created_at"),
          key: "createdAt",
          sortable: false,
        },
        {
          label: this.$i18n.t("products.end_at"),
          key: "activeTo",
          sortable: false,
        },
        {
          label: this.$i18n.t("products.category"),
          key: "productCategory",
          formatter: (value) =>
            `${value.productCategoryNameGeo} / ${value.productCategoryNameEng}`,
          sortable: false,
        },
        {
          label: this.$i18n.t("products.sub-category"),
          key: "productSubCategory",
          formatter: (value) =>
            `${value.productSubCategoryNameGeo} / ${value.productSubCategoryNameEng}`,
          sortable: false,
        },
        {
          label: this.$i18n.t("products.generic-category"),
          key: "productGenericCategory",
          formatter: (value) =>
            `${value.productGenericCategoryNameGeo} / ${value.productGenericCategoryNameEng}`,
          sortable: false,
        },
        {
          label: this.$i18n.t("products.status"),
          key: "status",
          formatter: (value) => this.productStatusList[value],
        },
        {
          label: this.$i18n.t("products.sku"),
          key: "upcOrSku",
        },
      ];
    },
  },
  watch: {
    products(newVal) {
      this.productsList = newVal.product;
    },
    searchKey() {
      this.fetchProducts();
    },
    activeOnly() {
      this.fetchProducts();
    },
    status() {
      this.fetchProducts();
    },
    id() {
      this.fetchProducts();
    },
    priceSort() {
      this.fetchProducts();
    },
    take() {
      this.fetchProducts();
    },
    sortOrder() {
      this.fetchProducts();
    },
  },
  created() {
    this.fetchProducts();
  },
  methods: {
    ...mapActions("products", ["getProducts", "deleteProductById"]),
    async fetchProducts() {
      try {
        await this.getProducts({
          skip: this.skip,
          take: this.take,
          searchKey: this.searchKey,
          activeOnly: this.activeOnly,
          sortOrder: this.sortOrder,
          status: this.status,
          id: this.id,
          priceSort: this.priceSort,
          productType: "review-products",
        });
        this.productsList = this.products.product;
        this.productTypeSwitch = "pending";
        this.totalRows = this.products.count;
      } catch (error) {
        console.log(error);
      }
    },
    dublicateProduct(item) {
      if (item.serviceType !== 4) {
        this.$router.push(`/copy-product/${item.id}`);
      } else {
        this.$router.push({
          name: "editSticker",
          params: { id: item.id, isApproved: false, isDuplicate: true },
        });
      }
    },
    onClickChild() {
      this.selectedProduct = null;
    },
    editProduct(item) {
      if (item.serviceType === 4) {
        this.$router.push(`/edit-sticker/${item.id}`);
      } else {
        this.$router.push(`/edit-product/${item.id}`);
      }
    },
    async removeProduct(item) {
      const result = await this.$bvModal.msgBoxConfirm(
          `${this.$t("global.delete_header")} - ${item.nameGeo} / ${item.nameEng}?`,
        {
          title: this.$t("global.delete_confirm"),
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: this.$t("global.yes"),
          cancelTitle: this.$t("global.no"),
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        }
      );

      if (result) {
        await this.deleteProductById(item.id);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `${item.nameGeo} / ${item.nameEng} ${this.$t("products.deleteSuccessMsg")}`,
            variant: "success",
          },
        });
        this.fetchProducts();
        this.$router.go();
        // this.getProducts({
        //   skip: this.skip,
        //   take: this.take,
        //   searchKey: this.searchKey,
        //   activeOnly: this.activeOnly,
        //   sortOrder: this.sortOrder,
        //   status: this.status,
        //   id: this.id,
        //   priceSort: this.priceSort,
        //   productType: "review-products",
        // });
      }
    },
    onRowClick(item) {
      this.selectedProduct = item;
      this.$nextTick(() => {
        this.$bvModal.show("product-show-modal");
      })
    },
    getMobileView(prod) {
      this.mobileViewProduct = prod;
      this.$bvModal.show("product-mobile-modal");
    },
  },
};
</script>
<style lang="scss">
.buttonsWrapper {
  display: flex;
  justify-content: space-between;
  @media (max-width: 600px) {
    flex-direction: column;
  }
}
.custom-search {
  display: flex;
  justify-content: flex-end;
  @media (max-width: 600px) {
    width: 100%;
    justify-content: flex-start;
  }
}
.form-group {
  @media (max-width: 600px) {
    width: 100%;
  }
}
.products-table th,
.products-table td {
  // min-width: 200px ;
  white-space: nowrap;
}
.nameColumn {
  width: 400px;
}
</style>
